/* eslint-disable no-dupe-keys */
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  button: {
    alignSelf: 'center',
  },
  content: {
    padding: 0,
  },
  ourHistory: {
    alignItems: 'center',
    background: 'transparent',
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(1, 0),
    padding: theme.spacing(1),
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(3, 0),
      padding: theme.spacing(3),
    },
  },
  ourHistoryDescription: {
    marginBottom: theme.spacing(2),
  },
  ourHistoryImg: {
    height: 200,
    marginBottom: theme.spacing(4),
    objectFit: 'cover',
    width: '100%',
  },
  ourHistoryTitle: {
    marginBottom: theme.spacing(2),
  },
  peopleDescription: {
    margin: theme.spacing(2, 1),
  },
  peopleItem: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(1, 0),
    padding: theme.spacing(3, 2),
  },
  peopleItemAvatar: {
    borderRadius: '50%',
    height: 212,
    marginBottom: theme.spacing(2),
    marginRight: 0,
    width: 212,
  },
  peopleItemDescription: {
    margin: theme.spacing(0.5, 0),
  },
  peopleItemId: {
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  peopleItemQualificationsTitle: {
    marginTop: theme.spacing(2),
    textAlign: 'start',
  },
  peopleItemSummary: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  peopleItemTitle: {
    textAlign: 'center',
  },
  peopleTitle: {
    marginTop: theme.spacing(4),
    textAlign: 'center',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2, 2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2, 3, 6),
    },
  },
  workWithUs: {
    margin: theme.spacing(6, 'auto', 3),
    textAlign: 'center',
  },
}));
