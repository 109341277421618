/* eslint-disable react/no-danger */
import { useTranslation } from 'react-i18next';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { AiOutlineCheck } from 'react-icons/ai';

import SEO from 'components/seo';
import { ROUTES } from 'assets/data/actpsicologos-app';
import AppLayout from 'app/AppLayout/AppLayout';
import ListSimple from 'components/List/ListSimple';
import useStyles from './Team.styles';

const Team = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <AppLayout
      headerTransparent={false}
      subheader={{
        title: t('routes.team.title'),
      }}
    >
      <SEO
        description={t('routes.team.meta.description')}
        title={t('routes.team.meta.title')}
      />
      <Container className={classes.root} maxWidth="md">
        <Typography className={classes.description} variant="body1">
          {t('routes.team.description')}
        </Typography>
        <Container className={classes.content} maxWidth="md">
          <Paper className={classes.ourHistory} variant="outlined">
            <StaticImage
              alt="nuestra-historia"
              className={classes.ourHistoryImg}
              src="../../assets/img/team-ourHistory.jpg"
              width={1280}
            />
            <Typography className={classes.ourHistoryTitle} variant="h4">
              {t('routes.team.ourHistory.title')}
            </Typography>
            <Box className={classes.ourHistoryDescription}>
              <Typography component="p" variant="body1">
                <div dangerouslySetInnerHTML={{ __html: t('routes.team.ourHistory.description') }} />
              </Typography>
            </Box>
          </Paper>
          <Typography className={classes.peopleTitle} variant="h4">
            {t('routes.team.people.title')}
          </Typography>
          <Typography className={classes.peopleDescription} variant="body2">
            {t('routes.team.people.description')}
          </Typography>
          <Paper className={classes.peopleItem}>
            <StaticImage
              alt="raquel martin gonzalez"
              className={classes.peopleItemAvatar}
              src="../../assets/img/team-raquel.jpg"
              width={720}
            />
            <Box className={classes.peopleItemSummary}>
              <Typography className={classes.peopleItemTitle} variant="h5">
                {t('routes.team.people.items.1.title')}
              </Typography>
              <Typography className={classes.peopleItemId} variant="body2">
                {t('routes.team.people.items.1.personalId')}
              </Typography>
              <Typography className={classes.peopleItemId} variant="body2">
                {t('routes.team.people.items.1.otherData')}
              </Typography>
              {Object.keys(t('routes.team.people.items.1.descriptionItems')).map((d, i) => (
                <Typography key={i} className={classes.peopleItemDescription} component="p">
                  {t(`routes.team.people.items.1.descriptionItems.${d}`)}
                </Typography>
              ))}
              <Typography className={classes.peopleItemQualificationsTitle} variant="h6">
                {t('routes.team.people.items.1.academicQualifications.title')}
                :
              </Typography>
              <ListSimple
                items={
                  Object.keys(t('routes.team.people.items.1.academicQualifications.items')).map(a => ({
                    icon: <AiOutlineCheck />,
                    text: t(`routes.team.people.items.1.academicQualifications.items.${a}`),
                  }))
                }
                size="small"
              />
            </Box>
          </Paper>
          <Box className={classes.workWithUs}>
            <Button
              color="primary"
              size="large"
              variant="contained"
            >
              <Link
                state={{
                  message: t('routes.team.workWithUs.contactMessage'),
                }}
                to={ROUTES.CONTACT}
              >
                {t('routes.team.workWithUs.button')}
              </Link>
            </Button>
          </Box>
        </Container>
      </Container>
    </AppLayout>
  );
};

export default Team;
